
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import SuggestionsSearchBar from '@/components/projects/SuggestionSearchBar.vue';

	@Component({
		name: 'ProjectsSearchBar',
		components: {
			SuggestionsSearchBar,
		},
	})
	export default class ProjectsSearchBar extends Vue {
		@Prop({ default: '' }) search: string;
		@Prop() placeholder: string;

		handleSearch(search: string) {
			const query = { ...this.$route.query };
			if (!search) {
				delete query.search;
			} else {
				query.search = search;
			}
			if (this.$route.query.search != query.search) this.$router.push({ query });
		}
	}
