import { render, staticRenderFns } from "./ProjectsSearchBar.vue?vue&type=template&id=8c732972&scoped=true&"
import script from "./ProjectsSearchBar.vue?vue&type=script&lang=ts&"
export * from "./ProjectsSearchBar.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectsSearchBar.vue?vue&type=style&index=0&id=8c732972&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c732972",
  null
  
)

export default component.exports