
	import { Component, Vue, Prop } from 'vue-property-decorator';

	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import ProjectsSearchBar from '@/components/projects/ProjectsSearchBar.vue';

	import { ProjectTypes } from '@pixcap/ui-core/models/store/projectexports.interface';
	import FilterInput from '@/components/pixcap-library/FilterInput.vue';

	@Component({
		name: 'ProjectsHeader',
		components: {
			FilterInput,
			HeadingText,
			ProjectsSearchBar,
		},
		computed: {},
	})
	export default class ProjectsHeader extends Vue {
		@Prop() readonly type: ProjectTypes;
		@Prop({ default: '' }) search: string;

		projectTypeTabs = [
			{ name: this.$t('common_elements.filter.type.animated_static'), value: undefined },
			{ name: this.$t('common_elements.filter.type.animated'), value: ProjectTypes.ANIMATED },
			{ name: this.$t('common_elements.filter.type.static'), value: ProjectTypes.STATIC },
		];

		get activeType() {
			const projectTypeTabs = this.projectTypeTabs;
			return projectTypeTabs.find((option) => option.value === this.type) || projectTypeTabs[0];
		}

		handleChangeProjectType(type?: ProjectTypes) {
			const query = { ...this.$route.query };
			if (type) query.type = type;
			else delete query.type;
			this.$router.push({ query });
		}
	}
