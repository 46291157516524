
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import debounce from 'lodash.debounce';
	import IconArrowDown from '@pixcap/ui-library/components/Icons/IconArrowDown.vue';
	import IconSearch from '@pixcap/ui-library/components/Icons/IconSearch.vue';
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';

	@Component({
		name: 'SuggestionsSearchBar',
		components: {
			IconArrowDown,
			IconSearch,
			IconClose,
		},
	})
	export default class SuggestionsSearchBar extends Vue {
		@Prop() resourceType: string;
		@Prop() itemType: string;
		@Prop() placeholder: string;
		@Prop() value: string;
		@Prop() disableSuggestions: string;

		$refs: {
			refSearchInput: HTMLInputElement;
		};

		searchInputFocused = false;
		search = '';

		debouncedOnChange = debounce(this.onChange, 400, {
			leading: false,
			trailing: true,
		});

		@Watch('value')
		onValueChange(next) {
			if (this.search != next) this.search = next;
		}

		onChange() {
			if (this.value && !this.search) this.submitSearch();
		}

		submitSearch() {
			if (this.search == this.value) return;
			this.$emit('onSearchChange', this.search);
		}

		handleClearSearch() {
			this.search = '';
			this.submitSearch();
		}

		mounted() {
			this.search = this.value;
		}
	}
