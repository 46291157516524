
	import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
	import { mapState, mapGetters } from 'vuex';
	import {
		NAMESPACE as USER_NAMESPACE,
		UserState,
		Getters as UserGetters,
		GetterTypes as UserGetterTypes,
	} from '@pixcap/ui-core/models/store/user.interface';
	import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';
	import SimpleEmptyState from '@pixcap/ui-library/components/SimpleEmptyState.vue';
	import logger from '@pixcap/ui-core/helpers/logger';
	import Spinner from '@pixcap/ui-library/components/Spinner.vue';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { mutationsWrapper as ProjectMutations, actionsWrapper as ProjectActions } from '@/store/project/wrapper';
	import { ProjectItem, ProjectTypes } from '@/models/store/project.interface';
	import ProjectCardItem from '@/components/projects/ProjectCardItem.vue';
	import DeleteConfirmModal from '@/common/DeleteConfirmModal.vue';
	import DuplicateProjectAsSubmissionModal from '@/components/projects/DuplicateProjectAsSubmissionModal.vue';
	import Pagination from '@/components/pixcap-library/shared/Pagination.vue';
	import AssetEmptyState from '@/components/pixcap-library/shared/AssetEmptyState.vue';

	@Component({
		name: 'ProjectsList',
		components: {
			AssetEmptyState,
			Pagination,
			DuplicateProjectAsSubmissionModal,
			Spinner,
			DeleteConfirmModal,
			ProjectCardItem,
			SimpleEmptyState,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				projectCount: (state: UserState) => state.personalData?.user?.projectCount,
			}),
			...mapGetters(USER_NAMESPACE, {
				isProOrAbove: UserGetterTypes.IS_PRO_OR_ABOVE,
				isContributor: UserGetterTypes.IS_CONTRIBUTOR,
			}),
		},
	})
	export default class ProjectsList extends Vue {
		@Prop() search: string;
		@Prop() type: ProjectTypes;
		@Prop() isLoading: boolean;
		@Prop() totalPages: number;
		@Prop() currentPage: number;
		@Prop({ default: false }) isSharedList: boolean;
		@Prop() listOfProjects: ProjectItem[];

		projectCount: UserState['personalData']['user']['projectCount'];
		isProOrAbove: ReturnType<UserGetters[UserGetterTypes.IS_PRO_OR_ABOVE]>;
		isContributor: ReturnType<UserGetters[UserGetterTypes.IS_CONTRIBUTOR]>;

		projectToBeDeleted: { project: ProjectItem; index: number } = null;
		projectToBeDuplicatedAsSubmission: ProjectItem = null;

		loadingAnimated = `${process.env.ROOT_URL}/cdn/app/editor/editor-loading.webp`;
		scrollCallbackName = 'projectsList.scrollCallback';

		@Watch('search')
		@Watch('sortBy')
		@Watch('type')
		onQueryChange() {
			this.getProjects(true);
		}

		getProjects(refresh: boolean) {
			this.$emit('getProjects', refresh);
		}

		duplicateProjectAsSubmission(project: ProjectItem | null) {
			this.projectToBeDuplicatedAsSubmission = project;
		}

		setProjectToBeDeleted(project: ProjectItem, index: number) {
			if (project) this.projectToBeDeleted = { project, index };
			else this.projectToBeDeleted = null;
		}

		setProjectToBeCloned(projectId) {
			ProjectMutations.setProjectsToDuplicate(this.$store, { projectToDuplicate: projectId, addToList: true });
			ProjectActions.cloneProject(this.$store, projectId)
				.then(() => {
					this.getProjects(true);
					UserMutations.updateProjectCount(this.$store, this.projectCount + 1);
				})
				.catch((err) => {
					logger.error(err, 'Encountered problem duplicating project.');
					this.$notify({
						type: NotificationType.ERROR,
						title: 'Encountered problem duplicating project. Please try again.',
						duration: 3000,
					});
				})
				.finally(() => {
					ProjectMutations.setProjectsToDuplicate(this.$store, { projectToDuplicate: projectId, addToList: false });
				});
		}

		handleClickDelete() {
			const projectToBeDeleted = this.projectToBeDeleted;
			this.projectToBeDeleted = undefined;
			ProjectActions.deleteProject(this.$store, projectToBeDeleted.project, projectToBeDeleted.index)
				.then(() => {
					// show notification
					this.$notify({
						type: NotificationType.SUCCESS,
						title: this.$t('toast.success_delete_project', { name: projectToBeDeleted.project.projectTitle }) as string,
						duration: 3000,
					});
					UserMutations.updateProjectCount(this.$store, this.projectCount - 1);
				})
				.catch((_err) => {
					this.$notify({
						type: NotificationType.ERROR,
						title: 'Encountered problem deleting project. Please try again.',
						duration: 3000,
					});
				});
		}

		handleSelectPage(page) {
			this.$emit('selectPage', page);
		}
	}
