
	import { Component, Mixins } from 'vue-property-decorator';
	import AspectRatioThumbnaiL from '@pixcap/ui-library/components/AspectRatioThumbnail.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import ProjectItemMixin from '@/components/projects/ProjectItemMixin';

	@Component({
		name: 'ProjectCardItem',
		components: { AspectRatioThumbnaiL, CaptionText },
	})
	export default class ProjectCardItem extends Mixins(ProjectItemMixin) {}
