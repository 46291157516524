
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import NewModal from '@pixcap/ui-core/components/common/NewModal.vue';
	import InputWrapper from '@pixcap/ui-library/components/InputWrapper.vue';
	import Button from '@pixcap/ui-library/components/Button.vue';
	import { SubmissionFileTypes, ProjectItem } from '@/models/store/project.interface';
	import { actionsWrapper as ProjectActions } from '@/store/project/wrapper';
	import Dropdown from '@/common/Dropdown.vue';

	@Component({
		name: 'DuplicateProjectAsSubmissionModal',
		components: {
			NewModal,
			Dropdown,
			InputWrapper,
			Button,
		},
	})
	export default class DuplicateProjectAsSubmissionModal extends Vue {
		@Prop() project: ProjectItem;
		submissionName = '';
		isLoading = false;

		get projectTitle() {
			return this.project.projectTitle;
		}

		@Watch('projectTitle')
		onProjectTitleChange(next) {
			this.submissionName = next;
		}

		options = [
			{
				name: SubmissionFileTypes.DESIGN_TEMPLATES,
				text: 'Static Template',
			},
			{
				name: SubmissionFileTypes.ANIMATED_DESIGN_TEMPLATES,
				text: 'Animated Template',
			},
			{
				name: SubmissionFileTypes.STATIC_MOCKUP_TEMPLATES,
				text: 'Static Mockup Template',
			},
			{
				name: SubmissionFileTypes.ANIMATED_MOCKUP_TEMPLATES,
				text: 'Animated Mockup Template',
			},
			{
				name: SubmissionFileTypes.STATIC_MODELS,
				text: 'Static Model',
			},
			{
				name: SubmissionFileTypes.ANIMATED_MODELS,
				text: 'Animated Model',
			},
			{
				name: SubmissionFileTypes.SHAPES,
				text: 'Shapes',
			},
			{
				name: SubmissionFileTypes.TEXT,
				text: 'Text',
			},
			{
				name: SubmissionFileTypes.TEXT_SHAPES,
				text: 'Text Shapes',
			},
			{
				name: SubmissionFileTypes.FRAMES,
				text: 'Frames',
			},
			{
				name: SubmissionFileTypes.CHARACTERS,
				text: 'Characters',
			},
			{
				name: SubmissionFileTypes.POSES,
				text: 'Poses',
			},
		];

		submissionType = this.options[0];

		handleSelectSubmission(option) {
			this.submissionType = option;
		}

		handleCloseModal() {
			if (this.isLoading) return;
			this.$emit('handleCloseModal');
		}

		createSubmission() {
			if (this.isLoading) return;
			this.isLoading = true;
			ProjectActions.duplicateProjectAsSubmission(this.$store, {
				projectId: this.project.projectId,
				submissionName: this.submissionName,
				submissionType: this.submissionType.name,
			}).finally(() => {
				this.isLoading = false;
				this.handleCloseModal();
			});
		}

		mounted() {
			this.submissionName = this.projectTitle;
		}
	}
