import { Vue, Prop, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import PopupMenu from '@pixcap/ui-library/components/PopupMenu.vue';
import { secondsToMMSS } from '@pixcap/ui-core/utils/DatetimeUtils';

/// icons list
import SymboleLogo from '@pixcap/ui-library/components/Assets/SymboleLogo.vue';
import IconBurger from '@pixcap/ui-library/components/Icons/IconBurger.vue';
import IconPopup from '@pixcap/ui-library/components/Icons/IconPopup.vue';
import DuplicateProjectLoading from '@/components/projects/DuplicateProjectLoading.vue';
import { NAMESPACE as PROJECT_NAMESPACE, ProjectState, ProjectItem } from '@/models/store/project.interface';
import { actionsWrapper as ProjectActions } from '@/store/project/wrapper';

@Component({
	name: 'ProjectItemMixin',
	components: {
		DuplicateProjectLoading,
		SymboleLogo,
		PopupMenu,
		IconBurger,
		IconPopup,
	},
	computed: {
		...mapState(PROJECT_NAMESPACE, {
			projectsToDuplicate: (state: ProjectState) => state.projectsToDuplicate,
		}),
	},
})
export default class ProjectItemMixin extends Vue {
	@Prop() readonly project: ProjectItem;
	@Prop() hideDelete: boolean;
	@Prop() hideRename: boolean;
	@Prop() showCloneSubmission: boolean;

	projectsToDuplicate: ProjectState['projectsToDuplicate'];

	showActions = false;
	isEditMode = false;
	projectTitle = '';

	$refs: {
		titleInput: HTMLInputElement;
	};

	get isAnimatedProject() {
		return this.project.animation;
	}

	get isDuplicating() {
		return this.projectsToDuplicate[this.project.projectId];
	}

	get projectThumbnail() {
		return this.project.thumbnailUrl;
	}

	get projectUrl() {
		return this.$router.resolve({
			name: 'CustomizerEditor',
			params: { projectUUID: this.project.projectId },
		}).href;
	}

	get animationMMSS() {
		if (this.project.animationDuration) return secondsToMMSS(this.project.animationDuration);
		return null;
	}

	toggleActions() {
		this.showActions = !this.showActions;
	}

	enterEditMode() {
		this.projectTitle = this.project.projectTitle;
		this.isEditMode = true;
		setTimeout(() => this.$refs.titleInput.focus(), 200);
	}

	renameProject() {
		const originalTitle = this.project.projectTitle;
		if (!this.projectTitle || this.projectTitle === originalTitle) {
			this.isEditMode = false;
			return;
		}
		ProjectActions.updateProjectTitle(this.$store, {
			projectId: this.project.projectId,
			projectTitle: this.projectTitle,
			originalTitle,
		}).then(() => (this.isEditMode = false));
	}
}
