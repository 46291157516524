
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import Spinner from '@pixcap/ui-library/components/Spinner.vue';

	@Component({
		name: 'DuplicateProjectLoading',
		components: {
			Spinner,
		},
	})
	export default class DuplicateProjectLoading extends Vue {
		@Prop() isVertical: boolean;
	}
